import React from 'react';
import DropdownMenu from './DropdownMenu';

import './App.css'

class Header extends React.Component {

    render(){

        const { largeWidthThresh, mediumWidthThresh, width } = this.props.props;

        if (width < mediumWidthThresh){
            return (
                <div className="navbar-section">
                    <div className="ui container" >
                        <div className={`ui secondary menu`}>
                            <a href="#home" className="item">
                                <img style={{ minWidth:0 }} src="logo192.png" alt="Lazing In Lagos Logo"></img>
                            </a>
                            <DropdownMenu />
                        </div>
                    </div>
                </div>
            );
        }


        let headerStyle = "";
        let logoSize = 70;

        if (width > largeWidthThresh){
            headerStyle = "massive";
            logoSize = 80;
        }

        return (

            <div className="navbar-section">
                <div className="ui container">
                    <div className={`ui secondary ${headerStyle} menu`}>
                        <a href="#home" className="item">
                            <img style={{ minWidth:logoSize }} src="logo192.png" alt="Lazing In Lagos Logo"></img>
                        </a>

                        <div className="right menu">
                            <a href="#about" className="item">
                                <div className="header-text">About</div>
                            </a>

                            <a href="#gallery" className="item">
                                <div className="header-text">Gallery</div>
                            </a>

                            <a href="#reviews" className="item">
                                <div className="header-text">Reviews</div>
                            </a>

                            <a href="#contact" className="item">
                                <div className="header-text">Contact</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
}

export default Header;