import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';

const Contact = () => {
    return (
        <div className="ui stackable grid">
            <div className="ui row">
                <h1 className="text-header" style={{width:"100%"}}>
                    <ScrollAnimation animateIn="rubberBand" duration={2} initiallyVisible={true}>
                        Contact
                    </ScrollAnimation>
                </h1>
            </div>

            <div className="ui row" style={{ paddingBottom: 10}}>
                <div className="content">
                    <div className="section">
                        <h2 className="text-header3">For any enquiries contact Charlotte</h2>
                        <ScrollAnimation animateIn="fadeIn" delay={500}>
                            <p className="text-content" >
                                <i className="fas fa-envelope review-icon" />
                                charlottedwyer5@gmail.com
                            </p>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>

            <div className="ui row" style={{ paddingBottom: 10}}>
                <div className="content">
                    <div className="section">
                    <ScrollAnimation animateIn="fadeIn" delay={1000}>
                        <p className="text-content" >
                            <i className="fas fa-phone review-icon" />
                            (+353) 85 162 9910
                        </p>
                    </ScrollAnimation>
                    </div>
                </div>
            </div>

            <div className="ui row" style={{ paddingBottom: 10}}>
                <div className="content">
                    <div className="section">
                    <ScrollAnimation animateIn="fadeIn" delay={1500}>
                        <p className="text-content" >
                            <i className="fab fa-airbnb review-icon" />
                            Visit us on our
                            <a className="external-link" rel="noopener noreferrer" href="https://www.airbnb.ie/rooms/23692859?s=51" target="_blank"> Airbnb page
                            </a>
                        </p>
                    </ScrollAnimation>
                    </div>
                </div>
            </div>

            <div className="ui row" style={{ paddingBottom: 10}}>
                <div className="content">
                    <div className="section">
                    <ScrollAnimation animateIn="fadeIn" delay={2000}>
                        <p className="text-content" >
                            <i className="fab fa-tripadvisor review-icon" />
                            Visit us on our
                            <a className="external-link" rel="noopener noreferrer" href="https://www.tripadvisor.ie/VacationRentalReview-g189117-d3423493-Ticks_All_The_Boxes_29143_AL-Lagos_Faro_District_Algarve.html" target="_blank"> Tripadvisor page
                            </a>
                        </p>
                    </ScrollAnimation>
                    </div>
                </div>
            </div>

            <div className="ui row" style={{ paddingBottom: 10}}>
                <div className="content">
                    <div className="section">
                    <ScrollAnimation animateIn="fadeIn" delay={2500}>
                        <p className="text-content" >
                            <i className="fas fa-home review-icon" />
                            Visit us on our
                            <a className="external-link" rel="noopener noreferrer" href="https://www.holidayhomesdirect.ie/11173/" target="_blank"> Holiday Homes Direct page
                            </a>
                        </p>
                    </ScrollAnimation>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Contact;
