import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const reviews = [
    "'I am recommending this apartement to my family and friends and we are already planning our return holiday next year' - Dawn",
    "'We stayed here in July for two weeks with our two young children and I couldn't recommend it highly enough' - Orla",
    "'This apartment has everything you could possibly want and the owner was a pleasure to deal with.Would definately stay here again,cant recommend it enough' - Alexandria",
    "'The apartment is in the perfect location - a stroll into the lovely old town bustling with stalls. street theatre and fantastic restauraunts - and a stroll in the other direction to the beautiful beach' - Niamh",
    "'Excellent apartment in superb location, close to all amenities - HIGHLY RECOMMENDED' - James",
    "'We will definitely be returning in the future' - Caroline",
    "'Well equipped, family friendly, centrally located apartment' - Coz",
    "'Charlotte is so lovely to deal with, very 'organised but easy going' - Anne",
    "'This is the second time we've stayed in Charlotte's apartment and again, it didn't disappoint' - Philip",
    "'From the minute I booked the hoilday I found Charlotte (the owner) very helpful and accommodating. The appartement was first class clean, tidy and plenty of room for the children' - Edel"
];

class Reviews extends React.Component {

    state = {
        reviews : reviews,
        reviewIndex : 0
    }

    selectReview = (e) => {

        this.setState({reviewIndex: Number(e.target.id)})

    }

    renderSelectionDots(){
        const { mediumWidthThresh, width } = this.props.props;

        return this.state.reviews.map((review, index) => {

            if (index === this.state.reviewIndex){
                if (width > mediumWidthThresh){
                    return <i key={index} id={(index).toString()} className="large circle icon sliderButton galleryDot galleryDotactive" onClick={this.selectReview}/>
                }
                return <i key={index} id={(index).toString()} className="circle icon sliderButton galleryDot galleryDotactive" onClick={this.selectReview}/>
            } 

            if (width > mediumWidthThresh){
                return <i key={index} id={(index).toString()} className="large circle outline icon sliderButton galleryDot" onClick={this.selectReview}/>
            }
            return <i key={index} id={(index).toString()} className="circle outline icon sliderButton galleryDot" onClick={this.selectReview}/>
        });
    }

    changeReview(){
        let nextReview = this.state.reviewIndex + 1;

        if (nextReview >= this.state.reviews.length){
            nextReview = 0;
        }

        this.setState({
            reviewIndex: Number(nextReview)
        });
    }

    componentDidMount(){
        this.interval = setInterval(() => this.changeReview(), 6000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){
        return(
            <div className="ui stackable grid">
                <div className="ui row">
                    <h1 className="text-header" style={{width:"100%"}}>
                        <ScrollAnimation animateIn="rubberBand" duration={2} initiallyVisible={true}>
                            Reviews
                        </ScrollAnimation>
                    </h1>
                </div>

                <div className="ui row" style={{height: 200}}>
                    <div className="ui container">
                    <div className="content">
                        <div className="section">
                            <p className="text-content">
                                {this.state.reviews[this.state.reviewIndex]}
                            </p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="ui row" style={{ paddingBottom: 20}}>
                    <div className="section">
                        <div className="eight wide column">
                            {this.renderSelectionDots()}
                        </div>
                    </div>
                </div>

                <div className="ui row" style={{ paddingBottom: 30, paddingTop: 30}}>
                    <div className="content">
                        <div className="section">
                            <p className="text-content">
                                <i className="fas fa-pencil-alt review-icon" />
                                For the full reviews and more check out our  
                                <a className="external-link" rel="noopener noreferrer" href="https://www.holidayhomesdirect.ie/11173/" target="_blank"> Holiday Homes Direct page
                                </a> and our
                                <a className="external-link" rel="noopener noreferrer" href="https://www.tripadvisor.ie/VacationRentalReview-g189117-d3423493-Ticks_All_The_Boxes_29143_AL-Lagos_Faro_District_Algarve.html" target="_blank"> Tripadvisor page
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reviews;
