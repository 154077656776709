import React from 'react';
import './App.css';

import ScrollAnimation from 'react-animate-on-scroll';

class About extends React.Component {

    renderLocationOrder(){

        const {  width } = this.props.props;

        if(width < 768){
            return(
                <div className="ui row" style={{paddingTop: 50}}>
                    <div className="eight wide column">
                        <div className="content">
                            <div className="section">
                                <h2 className="text-header2">Location</h2>
                                <p className="text-content">
                                    The apartment is located behind Marina da Lagos where there is a selection of 
                                    great restaurants and bars. The old town and beaches of Lagos are a 10 minute 
                                    walk from the apartment. Palmares and Boavista golf courses are 10 to 15 
                                    minutes drive away.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="eight wide column text-content">
                        <div className="content">
                        <iframe 
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBK4c0Q18nERCzHvmrAWPXlFGWFe95xUQU&q=37.111550,-8.669373&zoom=14" 
                            title="apartmentLocation" width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0">
                        </iframe>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="ui row" style={{paddingTop: 50}}>
                <div className="eight wide column text-content">
                    <div className="content">
                    <iframe 
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBK4c0Q18nERCzHvmrAWPXlFGWFe95xUQU&q=37.111550,-8.669373&zoom=14" 
                        title="apartmentLocation" width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0">
                    </iframe>
                    </div>
                </div>
                <div className="eight wide column">
                    <div className="content">
                        <div className="section">
                            <h2 className="text-header2">Location</h2>
                            <p className="text-content">
                                The apartment is located behind Marina da Lagos where there is a selection of 
                                great restaurants and bars. The old town and beaches of Lagos are a 10 minute 
                                walk from the apartment. Palmares and Boavista golf courses are 10 to 15 
                                minutes drive away.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){

    const { width } = this.props.props;

    return (
        <div style={{paddingBottom: 30,paddingTop: 30}} className="ui container">
            <div className="ui stackable grid">

            
                <div className="ui row">
                    <h1 className="text-header" style={{width:width}}>
                        <ScrollAnimation animateIn="rubberBand" duration={2} initiallyVisible={true}>
                            About
                        </ScrollAnimation>
                    </h1>
                </div>

                <div className="ui row">
                    <div className="eight wide column">
                        <div className="content">
                            <div className="section">
                                <p className="text-content">
                                    This site is dedicated to rental of our two-bedroom apartment property in Lagos, 
                                    Algarve, Portugal. The Apartment is located in the beautiful town of Lagos. Full 
                                    of beautiful beaches and scenery, fabulous restaraunts, vibrant nightlife and much 
                                    much more; Lagos has been a hidden gem of Europe for years which is quickly gaining 
                                    more and more spotlight. The apartment is bright, spacious and well furnished with 
                                    all the features and facilities to make it feel like a home from home. The apartment 
                                    has two large bedrooms, both with balconies, one overlooking the pool. The main 
                                    balcony leads off the second bedroom and lounge and has a large seating area with 
                                    BBQ and views toward the marina and town. Both balconies enjoy the sun from noon to 
                                    sundown.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="eight wide column text-content">
                        <div className="content">
                        <div className="section">
                            <img alt="apartment overlooking swimming pool" className="ui fluid circular image" src="/images/apartment/PoolView.jpg"/>
                        </div>
                        </div>
                    </div>
                </div> 

                {this.renderLocationOrder()}

                <div className="ui row" style={{paddingTop: 50}}>
                    <div className="content">
                        <div className="section">
                            <h2 className="text-header2">Amenities</h2>
                            <p className="text-content">
                            The lounge has WiFi, 140 TV stations, DVD player with bluetooth. There is a selection 
                            of cd's and dvd's for your enjoyment. The well equipped kitchen comes with washing 
                            machine, dishwasher, fridge/freezer oven, hob, grill, microwave, kettle and toaster. 
                            The bathroom has the benefit of both bath and shower. All rooms benefit from air 
                            conditioning/heating. The condominium in which the apartment is situated comes with 
                            swimming pool and a splash pool for children. There are sun loungers , shower and 
                            toilet facilities at the pool also. There is a garden area and a fantastic communal 
                            roof terrace with amazing panoramic views of the sea, Meia Praia, Marina Da Lagos 
                            and Lagos town. The apartment comes with a private secure parking space in the 
                            underground garage which is available to our guests. The apartment provides 
                            linen, towels, extra pillows, blankets and a hairdryer. We also provide beach 
                            towels and cooler box for those lazy days on the beach.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ui row">
                    <div className="content">
                        <div className="section">
                                <ScrollAnimation animateIn="shake" initiallyVisible={true}>
                                <i className="fas fa-wifi amenities-icons"/>
                                <i className="fas fa-swimmer amenities-icons" />
                                <i className="fas fa-parking amenities-icons" />
                                <i className="fas fa-umbrella-beach amenities-icons" />
                                <i className="fas fa-tv amenities-icons" />
                                <i className="fas fa-music amenities-icons" />
                                <i className="fas fa-mug-hot amenities-icons" />
                                </ScrollAnimation>
                        </div>
                    </div>
                </div>

                
                
                <div className="ui row" style={{paddingTop: 50}}>
                    <div className="eight wide column">
                        <div className="content">
                            <div className="section">
                                <h2 className="text-header2">Lagos Area</h2>
                                <p className="text-content">
                                Lagos is a truly beautiful typical portugese town and will appeal to those who want to 
                                see the real Algarve. The town has narrow cobbled streets that are pedestrianised with 
                                quaint restaurants, bars and typical portuguese architecture. The awardwinning marina is 
                                a hive of activity both day and night and you can book many differant boat trips here 
                                including dolphin watching, fishing and grotto tours. The town has a fishing harbour 
                                where the local fisherman can be seen bringing in their daily catches to the fish market. 
                                Lagos is a historic town with many places of interest to visit with old churches, museums 
                                and buildings to admire. The long sandy beach of Meia Praia is 4.5km long and never 
                                overcrowded even in high season. By night the town comes to life with fabulous restaurants 
                                and bars. There are street performers and musicians for your entertainment and Lagos has 
                                many festivals throughout the year with fireworks and concerts. If you want to travel 
                                around and see more of the Algarve there is both train and bus stations in the town 
                                and so car hire is not necessary. Great day trips for the family are the many waterparks 
                                around the Algarve or Zoo marine with its dolphin shows and funfair.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="eight wide column text-content">
                        <div className="content">
                            <div className="section">
                                <h2 className="text-header2">Getting There</h2>
                                <p className="text-content">
                                Fly to Faro airport with Aer Lingus and Ryanair seven days a week from Dublin, Cork, 
                                Shannon and Belfast. Transfers from faro airport to Lagos with yellow fish and resorthoppa.com. 
                                Car hire available at airport or book online with Argus.com for best deals. Regular daily 
                                train service from Faro to Lagos and then its minuites walk to apartment.
                                <br/>
                                <b>AIRPORT TRANSFERS CAN BE ARRANGED.</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 



            </div>
        </div>
    );
    }
}

export default About;
