import React from 'react';
import { Modal,  Image } from 'semantic-ui-react'

import './Gallery.css'
import ScrollAnimation from 'react-animate-on-scroll';

const images = [
    "/images/apartment/PoolView.jpg",
   "/images/apartment/Balcony.JPG",
   "/images/apartment/DoubleBed2.jpg",
   "/images/apartment/DoubleBed.jpg",
   "/images/apartment/DoubleBedBalcony.jpg",
   "/images/apartment/DoubleBedBalcony2.jpg",
   "/images/apartment/Lounge.JPG",
   "/images/apartment/Lounge2.JPG",
   "/images/apartment/Ornaments.JPG",
   "/images/apartment/SingleBeds.jpg",
   "/images/apartment/Cristal.jpg",
   "/images/apartment/Benagil.jpg",
   "/images/apartment/SeaView.jpg",
   "/images/apartment/SeaView2.jpg",
   "/images/apartment/SeaView3.jpg",
   "/images/apartment/LuzSangria.JPG"
];  

class Gallery extends React.Component {

    state = {
        images : images,
        imageIndex : 0
    }

    nextImage = () => {

        let newIdx = this.state.imageIndex + 1;

        if(newIdx >= this.state.images.length){
            this.setState({imageIndex: 0})
        }else{
            this.setState({imageIndex: newIdx});
        }
    }

    prevImage = () => {

        let newIdx = this.state.imageIndex - 1;

        if(newIdx < 0){
            this.setState({imageIndex: images.length-1})
        }else{
            this.setState({imageIndex: newIdx});
        }
    }

    selectImage = (e) => {

        this.setState({imageIndex: Number(e.target.id)})

    }

    renderSelectionDots(){
        const { mediumWidthThresh, width } = this.props.props;

        return this.state.images.map((image, index) => {

            if (index === this.state.imageIndex){
                if (width > mediumWidthThresh){
                    return <i key={index} id={(index).toString()} className="large circle icon sliderButton galleryDot galleryDotactive" onClick={this.selectImage}/>
                }
                return <i key={index} id={(index).toString()} className="circle icon sliderButton galleryDot galleryDotactive" onClick={this.selectImage}/>
            } 

            if (width > mediumWidthThresh){
                return <i key={index} id={(index).toString()} className="large circle outline icon sliderButton galleryDot" onClick={this.selectImage}/>
            }
            return <i key={index} id={(index).toString()} className="circle outline icon sliderButton galleryDot" onClick={this.selectImage}/>
        });
    }

    render(){
        const { largeWidthThresh, mediumWidthThresh, width } = this.props.props;

        let galleryHeight = "300px";

        if (width > mediumWidthThresh){
            galleryHeight = "400px";
        } 

        if (width > largeWidthThresh){
            galleryHeight = "500px";
        }

        return (
            <div className="ui stackable grid">
                <div className="ui row">
                    <h1 className="text-header" style={{width:"100%"}}>
                        <ScrollAnimation animateIn="rubberBand" duration={2} initiallyVisible={true}>
                            Gallery
                        </ScrollAnimation>
                    </h1>
                </div>

                <div className="ui row">
                    <div className="section">
                        <Modal trigger={<img 
                                alt="slideshow of apartment" 
                                src={this.state.images[this.state.imageIndex]}
                                className={`slideshow-image fade${this.state.imageIndex % 2}`}
                                style={{ height: `${galleryHeight}`,
                                    paddingBottom: 30, paddingTop: 30 }} 
                            />} closeIcon>
                                <Modal.Content>
                                <div className="ui stackable grid" style={{justifyContent: 'center',  maxHeight: '100%', paddingTop: 10, paddingBottom: 10}}>
                                <div className="ui row">
                                <Image wrapped className={`slideshow-image fade${this.state.imageIndex % 2}`} size='large' src={this.state.images[this.state.imageIndex]} />
                                </div>
                                <div className="ui row">
                                    <div className="content">
                                        <div className="section">
                                            <div className="eight wide column">
                                                <i className="huge chevron circle left icon sliderButton" onClick={this.prevImage}/>
                                            </div>
                                        </div>
                                        
                                        <div className="section">
                                            <div className="eight wide column">
                                                <i className="huge chevron circle right icon sliderButton" onClick={this.nextImage}/>      
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </Modal.Content>
                        </Modal>
                    </div>
                </div>

                <div className="ui row" style={{ paddingBottom: 20}}>
                    <div className="section">
                        <div className="eight wide column">
                            {this.renderSelectionDots()}
                        </div>
                    </div>
                </div>

                <div className="ui row">
                    <div className="content">
                        <div className="section">
                            <div className="eight wide column">
                                <i className="huge chevron circle left icon sliderButton" onClick={this.prevImage}/>
                            </div>
                        </div>
                        
                        <div className="section">
                            <div className="eight wide column">
                                <i className="huge chevron circle right icon sliderButton" onClick={this.nextImage}/>      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gallery;
