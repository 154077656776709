import React from 'react';
import throttle from 'lodash.throttle';

import Header from './Header';
import LandingImage from './LandingImage';
import About from './About';
import Gallery from './Gallery';
import Reviews from './Reviews';
import Contact from './Contact';
import Footer from './Footer';

import './App.css'

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            mediumWidthThresh: 600,
            largeWidthThresh: 1000
        }
        this.throttledHandleWindowResize = throttle(this.handleResize, 200)
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledHandleWindowResize);
    }

    render(){

        let paddingAnchor = 90;
        if (this.state.width < this.state.mediumWidthThresh){
            paddingAnchor = 60;
        }

        return (

            <div id="home" style={{width:  '100%'}}>
                <div className="navbar">
                    <div><Header props={this.state}/></div>
                </div>

                <div style={{overflow: "hidden"}}>
                    <div><LandingImage props={this.state}/></div>

                    <div id="about" style={{paddingTop:paddingAnchor, paddingBottom:paddingAnchor}}><About props={this.state}/></div>
                    <div id="gallery" className="darken-section" style={{paddingBottom:paddingAnchor, paddingTop:paddingAnchor}} >
                        <Gallery props={this.state}/>
                    </div>
                    <div id="reviews" style={{paddingTop:paddingAnchor, paddingBottom:paddingAnchor}}><Reviews props={this.state} /></div>
                    <div id="contact" style={{paddingTop:paddingAnchor, paddingBottom:paddingAnchor}} className="darken-section"><Contact /></div>
                    <div><Footer /></div>
                </div>
            </div>
        );
    }
};

export default App;