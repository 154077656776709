import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react'

import './App.css'

const options = [
    { key: 1, text: 'About', value: 1, href:"#about" },
    { key: 2, text: 'Gallery', value: 2, href:"#gallery" },
    { key: 3, text: 'Reviews', value: 3, href:"#reviews" },
    { key: 4, text: 'Contact', value: 4, href:"#contact"},
]

const DropdownMenu = () => (
        <Menu.Menu position='right' style={{alignItems: "center"}}>
            <Dropdown className="header-text" style={{fontSize:25}} text=' ' icon='list' options={options}/>
        </Menu.Menu>
)

export default DropdownMenu;