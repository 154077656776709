import React from 'react';

const Footer = () => {
    return (
        <div className="ui bottom attached header" style={{backgroundColor: "rgb(8, 163, 168)"}}>
            <div className="content">
                <div className="section">
                    <div className="ui container" style={{paddingTop: 20, paddingBottom: 20, color: "white"}}>
                        <div className="ui row">
                            Got with the flow <i className="fas fa-swimmer" />
                        </div>

                        <div className="ui row">
                            <i className="far fa-copyright" /> <a className="external-link-footer" rel="noopener noreferrer" href="https://flowywebsolutions.com/" target="_blank"> 2020 Flowy Web Solutions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;