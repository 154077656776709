import React from 'react';

import './LandingImage.css';

class LandingImage extends React.Component {

    render(){

        const { largeWidthThresh, mediumWidthThresh, width } = this.props.props;

        let bannerHeight = 300;

        if(width > mediumWidthThresh){
            bannerHeight = 450;
        }

        if(width > largeWidthThresh){
            bannerHeight = 600;
        }

        return(
            
                <div className="ui grid middle aligned">
                    <div className="ui row" style={{height:bannerHeight}}>     
                        <div className="ui column" style={{height:bannerHeight}}>

                            <div className="banner">
                                <video autoPlay muted loop src="videos/waves.mp4" alt="video" className="banner-vid" style={{height:bannerHeight, minWidth: width}}/>
                                <img className="banner-text" src="videos/waves-text.png" alt="video"/>
                            </div>

                        </div>    
                    </div>
                </div>
           
        );
    }
}

export default LandingImage;